import React, { Component } from 'react';
import { ParallaxProvider } from 'react-scroll-parallax';
import PageTransition from 'gatsby-plugin-page-transitions';
import Navigation from "../components/Header/Navigation.js";
import LazyLoad from "../components/repeating_elements/Lazy_Load.js";
import PageBreak from "../components/repeating_elements/page_break.js";
import ProjectItem from "../components/repeating_elements/Project_Item.js";
import GetInTouchFooter from "../components/Global/Get_In_Touch_Footer.js";
import Footer from "../components/Footer/Footer.js";
import BasicHero from "../components/Global/Basic_Hero.js";
import BackgroundImage from "../components/repeating_elements/Background-Image.js";
import EnterHeader from "../components/repeating_elements/Enter_Header.js";
import GDPR from '../components/Global/GDPR';
// import Loader from '../components/Global/Loader';
import SEO from '../components/seo.js';


export default class Services_Single extends Component {
	constructor(props){
		super(props);
		this.state = {
			isLoaded: this.props.pageContext.isLoaded,
			isLazyLoaded: this.props.pageContext.isLazyLoaded,
			isReady: this.props.pageContext.isReady,
			data: this.props.pageContext.data,
			cookieBanner: this.props.pageContext.cookieBanner,
			headerLinks: this.props.pageContext.header,
			footerLinks: this.props.pageContext.footer,
			featured_case_studies: this.props.pageContext.caseStudies
		}
		this.create_hero_section = this.create_hero_section.bind(this);
		this.create_overview_section = this.create_overview_section.bind(this);
		this.create_case_study_section = this.create_case_study_section.bind(this);
		this.create_callout_section = this.create_callout_section.bind(this);
		this.create_project_news_section = this.create_project_news_section.bind(this);
	}
	create_hero_section(data){
		return <LazyLoad>
			<BasicHero className="center" fullHeight={true}>
				<EnterHeader className="styling-header-1 service-hero-text">{data.title}</EnterHeader>
				<BackgroundImage url={data.hero_desktop_image} mobileURL={data.hero_mobile_image} alt={data.title}></BackgroundImage>
			</BasicHero>
		</LazyLoad>
	}
	create_overview_section(data){
		return <LazyLoad>
			<div className="grid service-overview">
				<div className="grid-m-6 grid-t-8 grid-d-8 description-container">
					<p className="styling-eyebrow service-eyebrow">Overview</p>
					<p className="overview-details">{data.description}</p>
				</div>
				<div className="grid-m-6 grid-t-8 push-d-2 grid-d-6 description-container services-container">
					<h3 className="styling-eyebrow service-eyebrow">{data.title} Services</h3>
					<ul>
						{(function(){
							var list = [];
							data.services_of_interests.forEach(function(i){
								list.push(<li key={i.service}>{i.service}</li>)
							})
							return list
						})()}
					</ul>
				</div>
			</div>
		</LazyLoad>
	}
	create_case_study_section(data){
		return <LazyLoad>
			{
				<div className="grid">
					<h3 className="grid-m-6 grid-t-16 grid-d-16 styling-eyebrow service-eyebrow">Featured Work</h3>
					<div className="row">
						{
							(()=>{
								if(this.state.featured_case_studies){
									var element = [];
									for (var i = 0; i < 4; i++) {
										const project_data = this.state.featured_case_studies[i];
										const postId = project_data.id;
										let override_data = this.state.data.acf.selected_works.find((i)=>{
											if(i.selected_work_project[0].ID === postId) {
												return i;
											}
										});
										console.log("check")
										console.log(project_data)
										console.log(override_data)
										console.log("end check")
										if(override_data.length > 0) {
											const project_title = override_data.selected_work_title;
											const project_desc = override_data.selected_work_description;

											if(project_title.length > 0) {
												project_data.title.rendered = project_title;
											}
											if(project_desc.length > 0) {
												project_data.acf.project_summary = project_desc;
											}

										}
										element.push(
											<div className="grid-m-6 grid-t-8 grid-d-8 related-case-study"><ProjectItem project={project_data} /></div>
										);
									}
									return element;
								}
							})()
						}
					</div>
				</div>
			}
		</LazyLoad>
	}
	create_callout_section(data){
		if(data.callout_text.length > 0){
			return <LazyLoad>
				<div className="case-study-content">
					<div className="grid csc-centered-text">
						<div className="grid-m-6 push-t-1 grid-t-14 push-d-1 grid-d-14 large-text"><p>{data.callout_text}</p></div>
						<div className="grid-m-6 grid-t-16 grid-d-16 info-text">
							<p className="credit">{data.callout_title}</p>
							<p className="sub-credit">{data.callout_subtitle}</p>
						</div>
					</div>
				</div>
				<PageBreak />
			</LazyLoad>
		}
		return null;
	}
	create_project_news_section(data){
		const element = [];
		data.project_news.forEach((i)=>{
			const data = {
				acf: {
					override_text_color: "",
					project_summary: i.selected_work_description,
					thumbnail: {
						sizes : {
							large: i.background
						}
					}
				},
				slugStructure: "chatbox",
				slug: i.link,
				categories: [],
				title: {
					rendered: i.selected_work_title
				}
			};
			element.push(
				<div className="grid-m-6 grid-t-8 grid-d-8 related-case-study"><ProjectItem project={data} /></div>
			);
		});
		return <LazyLoad>
			<div className="grid">
				<h2 className="grid-m-6 grid-t-16 grid-d-16 styling-eyebrow service-eyebrow">Project News</h2>
				<div className="row">
					{element}
				</div>
			</div>
		</LazyLoad>
	}
	componentDidMount() {

	}
	render() {
		let page_ready = this.state.isLoaded && this.state.isReady ? "page-ready page-loaded show-text" : "page-loading";
		let data = this.state.data;
		if(data){
			data = this.state.data.acf;
			return (
				<ParallaxProvider><PageTransition>
				<GDPR data={this.state.cookieBanner} />
				<SEO
					title={this.state.data.yoast_meta.yoast_wpseo_title}
					description={this.state.data.yoast_meta.yoast_wpseo_metadesc}
				/>
				<div className="site-contain">
					<Navigation links={this.state.headerLinks} />
						<div className={"page service-page " + page_ready}>
							{this.create_hero_section(data)}
							<div>
								{this.create_overview_section(data)}
								<PageBreak />
								{this.create_case_study_section(data)}
								<PageBreak />
								{this.create_callout_section(data)}
								{this.create_project_news_section(data)}
							</div>
							<PageBreak />
							<GetInTouchFooter />
							<Footer links={this.state.footerLinks} />
						</div>
					</div>
				</PageTransition></ParallaxProvider>
			);
		} else{
			return (
				<div></div>
			);
		}
	}
}
