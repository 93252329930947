import React, { Component } from "react";

import { Link } from 'gatsby';

import {luma_max, hex_to_luma} from "../utilities/hex_to_luma.js";
import truncate_string from "../utilities/truncate_string.js";
import BackgroundImage from "../repeating_elements/Background-Image.js";
import replace_url from "../utilities/replace_url.js";
import BrowserView from "../utilities/mobileViewCheck.js";
// import { Arrow_Link } from "../buttons/Arrow_Link.js";
// import link_realignment from "../utilities/link_realignment.js";

const pathRegEx = /^https?:\/\//i;

export default class Project_Item extends Component {
	constructor(props){
		super(props);
		this.state = {
			smallScreen: true
		};
		this.make_image = this.make_image.bind(this)
		this.make_video = this.make_video.bind(this)
		this.make_link = this.make_link.bind(this)
	}
	make_video(project_color){
		if (this.props.project.acf.video_thumbnail && this.props.project.acf.video_thumbnail !== false) {
			let thumbnail_video = false;
			if((this.state.smallScreen || this.props.tall === true) && this.props.project.acf.v_tall_thumbnail){
				thumbnail_video = this.props.project.acf.v_tall_thumbnail;
			} else if(this.props.wide === true && this.props.project.acf.v_wide_thumbnail){
				thumbnail_video = this.props.project.acf.v_wide_thumbnail;
			} else if(this.props.project.acf.v_thumbnail){
				thumbnail_video = this.props.project.acf.v_thumbnail;
			}
			if(thumbnail_video){
				return  <BrowserView video={thumbnail_video} fill={project_color} />;
			} else{
				return false;
			}
		}
		else return false
	}
	make_image(project_color){
		let thumbnail = this.props.project.acf.thumbnail || this.props.project.acf.slides[0].desktop_image;
		if(this.state.smallScreen || this.props.tall === true){
			thumbnail = this.props.project.acf.tall_thumbnail || thumbnail;
		} else if(this.props.wide === true ){
			thumbnail = this.props.project.acf.wide_thumbnail || thumbnail;
		}
		return <BackgroundImage url={thumbnail.sizes.large} fill={project_color} />;
	}
	make_link(){
		if(this.props.project.acf.lock_options === "external_link"){
			if(!pathRegEx.test(this.props.project.acf.lock_link)){
				return <Link to={this.props.project.acf.lock_link} title="Go to the project page"></Link>
			} else if (this.domainRegEx.test(this.props.project.acf.lock_link)){
				return <Link to={this.props.project.acf.lock_link} title="Go to the project page"></Link>
			} else{
				return <a href={this.props.project.acf.lock_link} target="_blank" rel="noopener noreferrer" title="See project"> </a>
			}
		} else{
			if(this.props.project.slugStructure === "chatbox"){
				return <Link to={replace_url(this.props.project.slug)}></Link>
			} else {

				return <Link to={"/work/"+this.props.project.slug}></Link>
			}
		}
	}
	componentDidMount() {
		this.domainRegEx = new RegExp(window.location.host);
		if (window.innerWidth >= 1000) this.setState({ smallScreen: false })
	}
	render() {
		if(!this.props.project){
			return false
		}
		let project_classes = "project-item";
		if(this.state.smallScreen || this.props.tall === true){
			project_classes += " tall-item";
		} else if(this.props.wide === true){
			project_classes += " wide-item";
		}
		let project_color = "#FFFFFF";
		if(this.props.project.acf){
			project_color = this.props.project.acf.project_color || "#FFFFFF";
		}
		if(this.props.project.acf.override_text_color){
			if(this.props.project.acf.project_text_color === "black"){
				project_classes += " dark-text";
			}
		} else{
			if(hex_to_luma(project_color) > luma_max){
				project_classes += " dark-text";
			}
		}
		var filter_is_active = this.props.project.categories.find((i)=>{
			if(i === this.props.filter){
				return i
			}
			return null
		})
		if(!filter_is_active && this.props.filter){
			project_classes += " fade-project";
		}
		if(this.props.classes){
			project_classes += " "+this.props.classes;
		}
		return <div className={project_classes}>
			{ this.make_link() }
			<div className="item-imagery">
				{/* <div className="frame"></div> */}
				<div className="fill" style={{backgroundColor: project_color }}></div>
				<div className="thumbnail">
					{this.make_video(project_color)}
					{this.make_image(project_color)}
				</div>
			</div>
			<div className="item-content">
				<p className="title" dangerouslySetInnerHTML={{__html: this.props.project.title.rendered}} />
				<p className="description">{ truncate_string(this.props.project.acf.project_summary) }</p>
				<div className="but">Discover ⟶</div>
			</div>
		</div>
	};
}
