import React, { Component } from "react";


export default class EnterHeader extends Component {
	constructor(props){
		super(props);
		this.state = {
			text: "",
			text_broken: false,
			ready: false,
			outside_classes: ''
		}
	}
	componentDidMount() {
		let outside_classes = this.props.className ? " " + this.props.className : "";
		if(!this.state.text_broken){
			let finale_text = [];
			if(this.props.children){
				let text = this.props.children.split(" ");
				text.forEach((i, x) => {
					finale_text.push(<span key={"enter-header-"+x} className="segment"><span>{i}</span></span>)
				})
				this.setState({
					text: finale_text,
					text_broken: true
				})
				// setTimeout(() =>{
					this.setState({
						ready: true
					})
				// }, 3000)
			}
		}
		this.setState({
			outside_classes: outside_classes
		})
	}
	render() {
		return <h1 className={"motion-header"+this.state.outside_classes}>{this.state.text}</h1>;
	}
}
