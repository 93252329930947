export function hex_to_luma(hex) {
	hex = hex.substring(1)
	return (
		(parseInt(hex.substring(0, 2), 16) * 299) +
		(parseInt(hex.substring(2, 4), 16) * 587) +
		(parseInt(hex.substring(4, 6), 16) * 114)
	) / 1000;
}

export const luma_max = 160;
